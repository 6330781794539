import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import Technology from '@/views/Technology.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  // {
  //   path: '/technology.html',
  //   name: 'Technology',
  //   component: Technology
  // },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
